import styled from '@emotion/styled';
import {
  Dialog, DialogContent,
} from '@mui/material';
import { WCLThemeProps } from 'utils/wclThemeProps';

export const CustomDialog = styled(Dialog)(() => ({
  // nothing needed yet
}));

export const CustomDialogContent = styled(DialogContent)(({ theme }: WCLThemeProps) => ({
  padding: '16px 23px 24px 28px',
  background: theme?.ourTheme.colors.app.container,
}));
