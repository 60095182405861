import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import styled from '@emotion/styled';
import { WCLThemeProps } from 'utils/wclThemeProps';

// the main container for the component
export const ScrollArea = styled(ScrollAreaPrimitive.Root)({
  width: 'inherit',
  height: 'inherit',
  overflow: 'hidden',
});

// the viewport area of the scroll area
export const ScrollAreaViewport = styled(ScrollAreaPrimitive.Viewport)({
  // In viewPort, radix add a dynamic div with display table and put
  // whole content inside it. Due to display table, MUI Grid overflow
  // the content in mobile devices (below 550 width) that's why we have
  // updated it to display block. Here is already open issue:
  // https://github.com/radix-ui/primitives/issues/926
  // we can pick the viewport style by data-state and can override as defined here:
  // https://www.radix-ui.com/docs/primitives/overview/styling#styling-a-state-1
  '&[data-radix-scroll-area-viewport]': {
    // To pick first-child inside viewport. We can use first-child but as material
    // ui uses @emotions which will dynamically add classes and divs that's why
    // it is recommended to use first-of-type: https://stackoverflow.com/a/66124825/3253756
    '& > :first-of-type': {
      display: 'block !important',
    },
  },
  // all these settings pulled from radix example
  width: '100%',
  height: '100%',
  borderRadius: 'inherit',
});

// here we style the scrollbar itself. this is the main component; we've styled the
// vertical bar only, we'll need to style the horizontal bar in future if we want to
// use that
export const ScrollAreaScrollbar = styled(ScrollAreaPrimitive.Scrollbar)(({ theme }: WCLThemeProps) => ({
  // these three settings were pulled from radix examples. not 100% sure what each does
  display: 'flex',
  userSelect: 'none',
  // disable browser handling of all panning and zooming gestures on touch devices
  touchAction: 'none',

  // these props we customized to match our app styling
  // padding of track; this is the part that the "thumb" (the pill that moves up and down as user scrolls)
  // sits in
  padding: 1,

  // background color of the track
  ...(theme?.themeMode === 'light' && {
    background: `hsl(${theme?.ourTheme.colors.app.navbar})`,
    // '&:hover': { background: `hsl(${theme?.ourTheme.colors.neutral.lightest})` },
  }),
  ...(theme?.themeMode === 'dark' && {
    background: `hsl(${theme?.ourTheme.colors.app.navbar})`,
    // '&:hover': { background: `hsl(${theme?.ourTheme.colors.neutral.lightest})` },
  }),

  // width of the track
  '&[data-orientation="vertical"]': { width: '10px' },
  // this transition comes into play when the user hovers over the track itself
  transition: 'background 160ms ease-out',
}));

// the "thumb"; that is the "pill" that users can click/grab with their mouse or grab on touchscreens and
// move the viewport up and down
export const ScrollAreaThumb = styled(ScrollAreaPrimitive.Thumb)(({ theme }: WCLThemeProps) => ({
  // color of the "pill"
  ...(theme?.themeMode === 'light' && {
    background: `hsl(${theme?.ourTheme.colors.neutral.lighter1})`,
  }),
  ...(theme?.themeMode === 'dark' && {
    // in dark mode, instead of using a gray we use the dark page background color. with some opacity
    // so its not quite as dark
    background: `hsla(${theme?.ourTheme.colors.app.page}, .9)`,
  }),
  borderRadius: '5px',
  // increase target size for touch devices https://www.w3.org/WAI/WCAG21/Understanding/target-size.html
  // this makes it easier for users to click/grab the thumb on smaller devices. we pulled *all* this code below
  // from radix examples, so hopefully it works well on most devices
  flex: 1,
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100%',
    minWidth: 44,
    minHeight: 44,
  },
}));

// the corner where both vertical and horizontal scrollbars meet
export const ScrollAreaCorner = styled(ScrollAreaPrimitive.Corner)({
  background: '#000000',
});
