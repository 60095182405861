import React, { useRef } from 'react';
import { Props } from './interface';
import { CustomTextField } from './index.style';

/**
 * Our main input component. The main MUI component is called a TextField; it's a complex component that is
 * actually made up of a formcontrol, which wraps a label and then actual input element. This makes styling
 * things a bit complicated. You'll notice in the styles file, we have to apply a lot of different class
 * heirarchies to get styling to be halfway correct. See:
 *
 * https://mui.com/material-ui/react-text-field/
 *
 * https://mui.com/material-ui/api/text-field/
 */

export const TextField: React.FunctionComponent<Props> = (props, ref) => {
  const {
    id,
    size = 'medium',
    label,
    type = 'text',
    fullWidth = true,
    disabled = false,
    value = '',
    onChange,
    error = false,
    helpertext = '',
    cp,
    inputCp,
    multiline = false,
    multilineMinRows,
    multilineMaxRows,
    onBlur,
  } = props;

  const modalizeRef = useRef<any>(ref);

  return (
    <CustomTextField
      id={id}
      {...cp}
      {...(size === 'small' ? { size: 'small' } : { })}
      InputLabelProps={{
        // this is needed, along with label below, to pin the label to the top of the input
        shrink: true,
      }}
      // this works
      // inputProps={{ 'data-testid': 'testing' }}
      inputProps={{ ...inputCp }}
      error={error}
      value={value}
      disabled={disabled}
      fullWidth={fullWidth}
      onChange={onChange}
      onBlur={onBlur}
      variant="outlined"
      helperText={error && helpertext}
      type={type}
      ref={modalizeRef}
      multiline={multiline}
      minRows={multilineMinRows}
      maxRows={multilineMaxRows}
      // this is needed to permanently put the label at the top of the box
      label={label}
    />
  );
};
