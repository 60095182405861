import Grid2 from '@mui/material/Unstable_Grid2';
import {
  Box,
} from '@mui/material';
import { vars } from '../../../utils/vars';
import { Props } from './interface';
import { CustomCard } from './index.style';
import { Ic, IcSvg } from '../../datadisplay';
import { Grid2Ct } from '../Grid2Ct';

// default -- text is center aligned
const CenterAlign = ({
  icon, text, showArrow, color,
} : any) => (
  <>
    <Grid2 xs={3}>
      <Box display="flex" justifyContent="flex-start">
        <IcSvg icon={icon} width={vars.svgIconStandardSize} height={vars.svgIconStandardSize} />
      </Box>
    </Grid2>

    {/* text */}
    <Grid2 xs={14} display="flex" alignItems="center" justifyContent="center" sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
      {text}
    </Grid2>

    {/* optional arrow */}
    <Grid2 xs={3} display="flex" alignItems="center" justifyContent="flex-end">
      { showArrow ? <Ic iconName="circle-chevron-right" color={color} iconStyle="duotone" size="rel-xl" /> : null}
    </Grid2>
  </>
);

// align = 'left', text and icon are left-aligned
const LeftAlign = ({
  icon, text, showArrow, color,
} : any) => {
  let icontextWidth = 20;
  if (showArrow) {
    icontextWidth = 16;
  }
  return (
    <>
      <Grid2 xs={icontextWidth}>
        <Box display="flex" justifyContent="flex-start" alignItems="center" sx={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
          <Box sx={{ width: vars.svgIconStandardSize, height: vars.svgIconStandardSize }}>
            <IcSvg icon={icon} width={vars.svgIconStandardSize} height={vars.svgIconStandardSize} />
          </Box>
&nbsp;&nbsp;&nbsp;&nbsp;
          {text}
        </Box>
      </Grid2>

      { /* optional arrow */ }
      { showArrow
        ? (
          <Grid2 xs={4} display="flex" alignItems="center" justifyContent="flex-end">
            <Ic iconName="circle-chevron-right" color={color} iconStyle="duotone" size="rel-xl" />
          </Grid2>
        ) : (null)}
    </>
  );
};

/**
 * A small, colored card we often use inside of CardStandard. This always has an icon and text title.
 *
 * It can be given onClick functionality (often used for showing a modal), or wrapped in a Link component
 * (typically with showArrow=true and hovercursor='cursor) to link to another page.
 */
export const CardSmallColor = (props: Props) => {
  const {
    color,
    text,
    icon,
    showArrow = false,
    cp,
    onClick,
    align,
    hovercursor = 'none',
    smallerfont = false,
  } = props;

  let alignText = 1;
  if (align === 'left') {
    alignText = 2;
  }

  const baseComponent = (
    <CustomCard
      color={color}
      onClick={onClick}
      {...cp}
      hovercursor={hovercursor}
      smallerfont={smallerfont}
    >
      <Grid2Ct columns={20}>
        { alignText === 1 ? (
          <CenterAlign
            icon={icon}
            text={text}
            showArrow={showArrow}
            color={color}
          />
        ) : (
          <LeftAlign
            icon={icon}
            text={text}
            showArrow={showArrow}
            color={color}
          />
        )}
      </Grid2Ct>
    </CustomCard>
  );

  return baseComponent;
};
